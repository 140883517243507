export * from './src/makeApiClient'
export * from './src/FunctionUrlClient'
export * from './src/fetchServiceConfig'

export * from './src/makeApiGatewayClient'

        import * as me from '@peachy/core-domain-client'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/codebuild/output/src3102476736/src/codestar-connections.eu-west-2.amazonaws.com/git-http/011528273016/eu-west-2/ba15a82a-f179-4aff-b6b0-ab0bddfd2d62/peachy-punnet/peachy-mono-repo/core-domain/client'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/core-domain-client'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    