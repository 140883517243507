export * from './src/types'
export * from './src/repositories/abstract/PeachyArrayRepository'
export * from './src/repositories/abstract/PeachyMapRepository'

export * from './src/repositories/AppointmentRepository'
export * from './src/repositories/BenefitsRepository'
export * from './src/repositories/ClaimActivityRepository'
export * from './src/repositories/EnquiryRepository'
export * from './src/repositories/InProgressRepository'
export * from './src/repositories/ManagedLifeRepository'
export * from './src/repositories/NonManagedLifeRepository'
export * from './src/repositories/PlanRepository'
export * from './src/repositories/PolicyRepository'
export * from './src/repositories/PreferencesRepository'

export * from './src/migrations/PeachyFlashRepoMigrationRunner'

        import * as me from '@peachy/flash-repo-peachy-client'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/codebuild/output/src3102476736/src/codestar-connections.eu-west-2.amazonaws.com/git-http/011528273016/eu-west-2/ba15a82a-f179-4aff-b6b0-ab0bddfd2d62/peachy-punnet/peachy-mono-repo/comp-kit/flash-repo/peachy-client'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/flash-repo-peachy-client'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    