export * from './src/flash-repo/IFlashDb'
export * from './src/flash-repo/FlashStoreScope'
export * from './src/flash-repo/FlashPathHandler'
export * from './src/flash-repo/MemFlashDb'
export * from './src/flash-repo/FlashRepo'
export * from './src/flash-repo/FlashStore'
export * from './src/flash-repo/path-builder/path-builder-domain'
export * from './src/flash-repo/flash-repo-domain'

export * from './src/repository/AbstractRepository'
export * from './src/repository/types'

export * from './src/SequentialExecutor'

export * from './src/migrate/migration-kit'

export * from './src/flash-repo-api'

export * from './src/sockets/flash-repo-socket-api'


export * from './src/merge/lcsMerge'

        import * as me from '@peachy/flash-repo-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/codebuild/output/src3102476736/src/codestar-connections.eu-west-2.amazonaws.com/git-http/011528273016/eu-west-2/ba15a82a-f179-4aff-b6b0-ab0bddfd2d62/peachy-punnet/peachy-mono-repo/comp-kit/flash-repo/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/flash-repo-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    